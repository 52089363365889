<template>
  <router-view />
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  },
  created() {
    const oHtml = document.getElementsByTagName("html")[0]
    const width = oHtml.clientWidth
    if (width <= 750) {
      this.$router.push('/mobile')
    }
    else {
      this.$router.push('/')
    }

    window.addEventListener('resize', () => {
      const oHtml = document.getElementsByTagName("html")[0]
      const width = oHtml.clientWidth
      if (width <= 750) {
        this.$router.push('/mobile')
      }
      else {
        this.$router.push('/')
      }
    })
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
