<template>
  <div class="mobilefooter">
    <div class="footerlogo">
      <img src="../img/mobilelogo.png" alt="">
    </div>
    <div class="footerlink">
      <ul class="link_list">
        <li class="link_item" @click="gotoabout">About Us</li>
        <li class="link_item" @click="gotoview('mservice')">Terms of Service</li>
        <!-- <li class="link_item" @click="$router.push('mservice')">Terms of Service</li> -->
        <li class="link_item" @click="gotoview('mprivacypolicy')">Privacy Policy</li>
        <li class="link_item" @click="gotocontact">Contact Us</li>
        <li class="link_item" @click="gotoview('mrefundpolicy')">Refund Policy</li>
        <li class="link_item" @click="gotoview('mcancellationpolicy')">Cancellation Policy</li>
      </ul>
    </div>
    <div class="footertext">
      <p>B 6 KHASRA NO 75/2 GROUND FLOOR NEAR 
        BENGAL SWEETS, KRISHAN VIHAR NEW DELHI North West DL 110086 IN </p>
      <p style="margin:.12rem 0">JOYSEUS COMMERCE PRIVATE LIMITED</p>
      <p>© 2022 Baat Ltd. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoabout() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobileabout: 1
        }
      })
      return
    },
    gotocontact() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobilecontact: 1
        }
      })
      return
    },
    gotoview(path) {
      this.$router.push(`${path}`)
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilefooter.scss">

</style>