<template>
  <div class="mdetail">
    <div class="mdetail_head">
      <div class="logo" @click="gotohome"><img src="../img/mobilelogo.png" /></div>
      <div class="navlist" @click="gotonav"><img src="../img/mobilenavlist.png" /></div>
    </div>
    <div class="detail_title">{{ deteil_title }}</div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  props: ['deteil_title'],
  methods: {
    ...mapMutations(['changenavshow']),
    gotohome() {
      this.$router.push('/mobile')
    },
    gotonav() {
      console.log('navvv');
      // this.$refs.shownav.style.display = 'block'
      this.changenavshow(true)
    },
  },
}
</script>

<style lang="scss" scoped src="./mdateil.scss">
</style>