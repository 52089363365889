<template>
  <div class="mobileh">
    <!-- <div class="shownav" v-if="navshow">
      <div class="shownav_head">
        <div class="logo">
          <img src="../img/mobilelogo.png">
        </div>
        <div class="closebtn" @click="closenav">
          <img src="../img/nav_close.png">
        </div>
      </div>
      <div class="shownav_body">
        <ul class="nav_list" @click="closenav">
          <li class="nav_item" @click="gotoabout"><span >About Us</span><img src="../img/nav_right.png"/></li>
          <li class="nav_item" @click="$router.push('mservice')"><span>Terms of Service</span><img src="../img/nav_right.png"/> </li>
          <li class="nav_item" @click="$router.push('mprivacypolicy')"><span>Privacy Policy</span><img src="../img/nav_right.png"/> </li>
          <li class="nav_item" @click="gotocontact"><span >Contact Us</span><img src="../img/nav_right.png"/> </li>
          <li class="nav_item" @click="$router.push('mrefundpolicy')"><span>Refund Policy</span><img src="../img/nav_right.png"/> </li>
          <li class="nav_item" @click="$router.push('mcancellationpolicy')"><span>Cancellation Policy</span><img src="../img/nav_right.png"/> </li>
        </ul>
      </div>
    </div> -->

    <div class="mobile_header">
      <div class="head">
        <div class="logo"><img src="../img/mobilelogo.png"></div>
        <div class="navbtn" @click="gotonav"><img src="../img/mobilenavlist.png"></div>
      </div>
      <div class="headimg">
        <img src="../img/mobileheadimg.png">
      </div>
      <div class="headtext">
        <p>Indian Best App</p>
        <p>short video</p>
        <p>& voice room</p>
      </div>
      <div class="headbtn" @click="downLoadAPK">Free Download</div>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState(['navshow'])
  },
  methods: {
    ...mapMutations(['changenavshow']),
    gotonav() {
      // this.$refs.shownav.style.display = 'block'
      this.changenavshow(true)
    },
    closenav() {
      this.changenavshow(false)
      // this.$refs.shownav.style.display = 'none'
    },
    gotoabout() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobileabout: 1
        }
      })
      return
    },
    gotocontact() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobilecontact: 1
        }
      })
      return
    },
    downLoadAPK() {
      // window.open('./apps/wakapro/wakapro_release.apk', '_blank');
      var timestamp = Date.parse(new Date());
      var apklink = `./apps/Baat-Release.apk?t=${timestamp}`;
      //----- 创建隐藏的可下载链接
      var eleLink = document.createElement('a');
      eleLink.setAttribute("href", apklink);
      eleLink.setAttribute("download", 'Baat-Release.apk');
      eleLink.style.display = 'none';
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    }
  }
}
</script>

<style lang="scss" scoped src="./mobileheader.scss">
</style>