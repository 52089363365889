<template>
  <div class="detail">
    <div class="header_nav wrap">
      <div class="l" @click="gotohome">
        <a href="">
          <img class="logo" src="../img/logo.png" />
        </a>
      </div>
      <div class="r">
        <Nav :hrefList="hrefList"></Nav>
      </div>
    </div>
    <p class="detail_title">{{deteil_title}}</p>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
export default {
  props:['deteil_title'],
  components: {
    Nav
  },
  methods:{gotohome(){
    this.$router.push('/')
  }},
  data() {
    return {
      hrefList: ['About Us', 'Terms of Service', 'Privacy Policy',
        'Contact Us', 'Refund Policy', 'Cancellation Policy'],
    }
  },

}
</script>

<style lang="scss" scoped src="./detail.scss">
</style>