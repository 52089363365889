<template>
  <div class="content">
    <div class="wrap">
      <div class="infous mg wow animate__fadeInUp" data-wow-duration ="0.5s" data-wow-delay ="0s" id="about">
        <div class="l">
          <img src="../img/infousimg.png">
        </div>
        <div class="r">
          <p class="text_title" style="margin-bottom:30px">Who Are We?</p>
          <p class="text_content text_opc">Baat is India’s Short Video App. This is a platform to forecast talents to
            the whole
            world. This funny video community app lets you follow your favourite entertainer for good fun and
            entertainment. So bring your Baat up because this App has become a trendsetter on social media now!</p>
          <p class="text_content text_opc">Baat is your perfect entertainment partner.</p>
          <p class="text_footer">JOYSEUS COMMERCE PRIVATE LIMITED</p>
        </div>
      </div>
      <div class="infocan mg wow animate__fadeInUp" data-wow-duration ="1s" data-wow-delay ="0s">
        <div class="l">
          <p class="text_title" style="margin-bottom:30px">What you can do on Baat? ALL OF THESE!!</p>
          <p class="text_content" style="margin-bottom:20px">
            <span class="ico">🎉</span>
            <span class="text_opc">Live Streaming<br />
              Baat is an exciting way to interact with celebrities and users from everywhere. See your favorite users
              and
              chat with them. Meet new people and make new friendships.</span>
          </p>
          <p class="text_content" style="margin-bottom:20px">
            <span class="ico">🎁</span>
            <span class="text_opc">Virtual Gifts<br />
              Show your appreciation by sending broadcasters virtual gifts. There are lots of cool gifts to send, of
              different sizes. By sending gifts, you gain access to bonus levels and prizes.</span>
          </p>

          <p class="text_content">
            <span class="ico">🔥</span>
            <span class="text_opc">Join Us<br />
              Become a broadcaster! Interact with your fans and get rewarded for it. We are here to support you and help
              with your growth. Convert your followers from other platforms and maximize your impact with Baat
              streaming.
              Leverage the power of the platform to make sponsorship deals to take things over the top.</span>
          </p>

        </div>
        <div class="r">
          <img src="../img/infocanimg.png">
        </div>
      </div>
      <div class="chatnow mg wow animate__fadeInUp" data-wow-duration ="1s" data-wow-delay ="0s">
        <p class="text_title" style="margin-bottom:36px">
          Let's broadcast now
        </p>
        <div class="swp">
          <!-- :interval="1000000"  -->
          <el-carousel type="card" height="541px" class="paly_carousel" :interval="1000000">
            <el-carousel-item v-for="(item) in carImgList" :key="item">
              <img :src="item" class="medium" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="contectus mg wow animate__fadeInUp" data-wow-duration ="1s" data-wow-delay ="0s" id="contact">
        <p class="text_title">Contact Us</p>

        <div class="input_concent">
          <div class="input_item">
            <img src="../img/user_input.png" alt="" width="100%">
            <input type="text" v-model="insertInfo.user" placeholder="Please enter name">
          </div>
          <div class="input_item">
            <img src="../img/email_input.png" alt="" width="100%">
            <input type="text" v-model="insertInfo.email" placeholder="Please enter E-mail">
          </div>
          <div class="input_item">
            <img src="../img/phone_input.png" alt="" width="100%">
            <input type="text" v-model="insertInfo.phone" placeholder="Please enter Phone">
          </div>
        </div>
        <textarea class="message_input" type="textarea" placeholder="Please enter Message"
          v-model="insertInfo.message"></textarea>
        <el-button class="send" :plain="true" @click="thro">Send</el-button>
        <div class="cusinfo">
          <p>
            <span>Customer care number: </span>
            <span class="cusinfo_value">+91 6307360658</span>
          </p>
          <p>
            <span>Customer care email ID: </span>
            <span class="cusinfo_value">joycommpl@gmail.com</span>
          </p>
          <p style="margin-top:16px;">
            JOYSEUS COMMERCE PRIVATE LIMITED
          </p>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
export default {
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["contact"]) {
          this.toConcatUs()
        }
        if (this.$route.query["about"]) {
          this.toAboutus()
        }
      }
    }
  },
  data() {
    return {
      carImgList: [
        require('../img/car_img1.png'),
        require('../img/car_img2.png'),
        require('../img/car_img3.png')
      ],
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: ''
      },
      timer: null,
      flag: false
    }
  },
  mounted() {

    if (this.$route.query["contact"]) {
      this.toConcatUs()
    }
    if (this.$route.query["about"]) {
      this.toAboutus()
    }
    new this.$wow.WOW({
      live :true
    }).init()

  },
  methods: {
    toConcatUs() {
      document.getElementById(`contact`).scrollIntoView()
      this.$router.push('/')
    },
    toAboutus() {
      document.getElementById(`about`).scrollIntoView()
      this.$router.push('/')
    },



    throttle(func, wait = 300, immediate = true) {
      if (immediate) {
        if (!this.flag) {
          this.flag = true;
          // 如果是立即执行，则在wait毫秒内开始时执行
          typeof func === 'function' && func();
          this.timer = setTimeout(() => {
            this.flag = false;
          }, wait);
        }
      } else {
        if (!flag) {
          this.flag = true
          // 如果是非立即执行，则在wait毫秒内的结束处执行
          this.timer = setTimeout(() => {
            this.flag = false
            typeof func === 'function' && func();
          }, wait);
        }

      }
    },
    thro() {
      this.throttle(this.SendMessage, 60000)
      // this.throttle(this.SendMessage, 1000)
    },

    SendMessage() {
      console.log('sendddd');
      this.insertInfo.user = ''
      this.insertInfo.email = ''
      this.insertInfo.phone = ''
      this.insertInfo.message = ''
      this.$message({
        message: 'send successfully',
        type: 'success'
        //type: warning
        // this.$message.error('shibai');
      })
    }

  }
}
</script>

<style lang="scss" scoped src="./content.scss">
</style>