<template>
  <div class="footer">
    <div class="wrap">
      <img src="../img/logo.png" class="footer_logo">
      <Nav :hrefList="hrefList" style="width:70%"></Nav>
      <p style="text-align: right; color: rgba(255, 255, 255, 0.7500); font-size: 14px;">JOYSEUS COMMERCE PRIVATE LIMITED</p>
      <div class="footer_text">
        <p>© 2022 Baat Ltd. All Rights Reserved.</p>
        <p>B 6 KHASRA NO 75/2 GROUND FLOOR NEAR <br/>  BENGAL SWEETS, KRISHAN VIHAR NEW DELHI North West DL 110086 IN </p>
      </div>
    </div>

  </div>
</template>

<script>
import Nav from '../Nav/Nav.vue'
export default {
  components: {
    Nav,
  },
  data() {
    return {
      hrefList: ['About Us', 'Terms of Service', 'Privacy Policy',
        'Contact Us', 'Refund Policy', 'Cancellation Policy']
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped src="./footer.scss">
</style>