import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TermsOfServie from '@/views/TermsOfServie/TermsOfServie'
import CancellationPolicy from '@/views/CancellationPolicy/CancellationPolicy'
import PrivacyPolicy from '@/views/PrivacyPolicy/PrivacyPolicy'
import RefundPolicy from '@/views/RefundPolicy/RefundPolicy'

import Mobile from '@/views/Mobile/Mobile'
import MobileTermsOfServie from '@/views/MobileTerOfSer/MobileTerOfSer.vue'
import MobileCancellationPolicy from '@/views/MobileCanPo/MobileCanPo'
import MobilePrivacyPolicy from '@/views/MobilePriPo/MobilePriPo'
import MobileRefundPolicy from '@/views/MobileRefundPo/MobileRefundPo'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/service',
    name: 'Service',
    component: TermsOfServie
  },
  {
    path: '/cancellationpolicy',
    name: 'Cancellationpolicy',
    component: CancellationPolicy
  },
  {
    path: '/privacypolicy',
    name: 'Privacypolicy',
    component: PrivacyPolicy
  },
  {
    path: '/refundpolicy',
    name: 'Refundpolicy',
    component: RefundPolicy
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile,
  },
  {
    path: '/mservice',
    name: 'MobileService',
    component: MobileTermsOfServie
  },
  {
    path: '/mcancellationpolicy',
    name: 'MobileCancellationpolicy',
    component: MobileCancellationPolicy
  },
  {
    path: '/mprivacypolicy',
    name: 'MobilePrivacypolicy',
    component: MobilePrivacyPolicy
  },
  {
    path: '/mrefundpolicy',
    name: 'MobileRefundpolicy',
    component: MobileRefundPolicy
  },

]

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
