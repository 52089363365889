<template>
  <div class="mobile_content">
    <div class="moblieinfous_box" id="about">
      <img src="../img/mobileboximg1.png">
      <div class="mobiletext">
        <p class="mobile_texttitle" style="margin-bottom: .12rem;">Who Are We?</p>
        <p class="mobile_textcontent mobile_textopc">Baat is India’s Short Video App. This is a platform to forecast
          talents to the whole world. This funny video community app lets you follow your favourite entertainer for good
          fun and entertainment. So bring your Baat up because this App has become a trendsetter on social media now!
        </p>
        <p class="mobile_textcontent mobile_textopc" style="margin:.12rem 0">Baat is your perfect entertainment partner.
        </p>
        <p class="mobile_textcontent mobile_textopc">JOYSEUS COMMERCE PRIVATE LIMITED</p>
      </div>
    </div>
    <div class="mobileinfocan_box">
      <img src="../img/mobileboximg2.png">
      <div class="mobiletext">
        <p class="mobile_texttitle" style="margin-bottom: .12rem;">What you can do on Baat? All Of These !</p>
        <p class="mobile_textcontent">
          <span class="mobile_textico">🎉</span>
          <span class="mobile_textopc">Live Streaming<br />
            Baat is an exciting way to interact with celebrities and users from everywhere. See your favorite users and
            chat with them. Meet new people and make new friendships.
          </span>
        </p>
        <p class="mobile_textcontent" style="margin: .12rem 0">
          <span class="mobile_textico">🎁</span>
          <span class="mobile_textopc">Virtual Gifts<br />Show your appreciation by sending broadcasters virtual gifts.
            There are lots of cool
            gifts to send, of different sizes. By sending gifts, you gain access to bonus levels and prizes.
          </span>
        </p>
        <p class="mobile_textcontent">
          <span class="mobile_textico">🔥</span>
          <span class="mobile_textopc">Join Us<br />Become a broadcaster! Interact with your fans and get rewarded for
            it. We are here to
            support you and help with your growth. Convert your followers from other platforms and maximize your impact
            with Baat streaming. Leverage the power of the platform to make sponsorship deals to take things over the
            top.
          </span>
        </p>
      </div>
    </div>

    <div class="mobilechatnow_box">
      <p class="mobile_texttitle" style="margin-bottom:.24rem">Let's broadcast now</p>
      <div class="mobileswp">
        <!-- :interval="1000000"  -->
        <el-carousel type="card" height="3.5rem" class="paly_carousel" >
          <el-carousel-item v-for="(item) in carImgList" :key="item">
            <img :src="item" class="medium" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    
    <div class="mobilecontactus_box" id="contact">
      <p class="mobile_texttitle" style="margin-bottom:.24rem">Contact Us </p>
      <div class="mobileconbody">
        <div class="input_item marbtom">
          <img src="../img/user_input.png">
          <input type="text" placeholder="Please enter name" v-model="insertInfo.user" />
        </div>
        <div class="input_item marbtom">
          <img src="../img/email_input.png">
          <input type="text" placeholder="Please enter E-mail" v-model="insertInfo.email" />
        </div>
        <div class="input_item marbtom">
          <img src="../img/phone_input.png">
          <input type="text" placeholder="Please enter Phone" v-model="insertInfo.phone" />
        </div>
        <textarea class="message_input marbtom" placeholder="Please enter Message"
          v-model="insertInfo.message"></textarea>
        <el-button class="send marbtom" :plain="true" @click="thro">Send</el-button>
        <div class="info_text">
          <p><span style="opacity: .75;">Customer care number: </span><span>+91 6307360658</span></p>
          <p><span style="opacity: .75;">Customer care email ID: </span><span>joycommpl@gmail.com</span></p>
          <p style="opacity: .75;font-size: .16rem;">JOYSEUS COMMERCE PRIVATE LIMITED</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carImgList: [
        require('../img/car_img1.png'),
        require('../img/car_img2.png'),
        require('../img/car_img3.png')
      ],
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: ''
      },
      timer: null,
      flag: false
    }
  },
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["mobilecontact"]) {
          this.toConcatUs()
        }
        if (this.$route.query["mobileabout"]) {
          this.toAboutus()
        }
      }
    }
  },
  mounted() {
    if (this.$route.query["mobilecontact"]) {
      this.toConcatUs()
    }
    if (this.$route.query["mobileabout"]) {
      this.toAboutus()
    }

  },
  methods: {

    toConcatUs() {
      
      document.getElementById(`contact`).scrollIntoView()
      this.$router.push('/mobile')
    },
    toAboutus() {
      document.getElementById(`about`).scrollIntoView()
      this.$router.push('/mobile')
    },

    throttle(func, wait = 300, immediate = true) {
      if (immediate) {
        if (!this.flag) {
          this.flag = true;
          // 如果是立即执行，则在wait毫秒内开始时执行
          typeof func === 'function' && func();
          this.timer = setTimeout(() => {
            this.flag = false;
          }, wait);
        }
      } else {
        if (!flag) {
          this.flag = true
          // 如果是非立即执行，则在wait毫秒内的结束处执行
          this.timer = setTimeout(() => {
            this.flag = false
            typeof func === 'function' && func();
          }, wait);
        }

      }
    },
    thro() {
      this.throttle(this.SendMessage, 60000)
    },

    SendMessage() {
      console.log('sendddd');
      this.insertInfo.user = ''
      this.insertInfo.email = ''
      this.insertInfo.phone = ''
      this.insertInfo.message = ''
      this.$message({
        message: 'send successfully',
        type: 'success'
        //type: warning
        // this.$message.error('shibai');
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./mobilecontent.scss">
</style>